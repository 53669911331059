<template>
  <div>
    <el-card class="box-card card-contanier">
      <!-- <div slot="header" class="clearfix card-header">
        <span>事件操作</span>
      </div> -->
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input v-model="searchForm.cdType" size="medium" placeholder="类别" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.typeDesc" size="medium" placeholder="类别描述" clearable></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="getDictList" size="medium">搜索</el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="dialogVisible = true"
              size="medium"
            >新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{height:'10px'}"
      :cell-style="{padding:'1px 0'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center"></el-table-column>

      <el-table-column prop="cdType" label="类别" width="220" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="类别描述" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="cdCode" label="代码值" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="codeDesc" label="代码描述" show-overflow-tooltip align="center"></el-table-column>
<!--      <el-table-column prop="parentType" label="上级类别" show-overflow-tooltip align="center"></el-table-column>-->
<!--      <el-table-column prop="parentCode" label="上级代码" show-overflow-tooltip align="center"></el-table-column>-->


      <el-table-column fixed="right" prop="icon" width="220px" label="操作" align="center">
        <template slot-scope="scope">

          <el-button type="text" @click="editHandler(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="确认删除？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!--新增对话框-->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="600px" :before-close="handleClose">
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="类别" prop="cdType" label-width="100px">
          <el-input v-model="editForm.cdType" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="类别描述" prop="typeDesc" label-width="100px">
          <el-input v-model="editForm.typeDesc" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="代码值" prop="cdCode" label-width="100px">
          <el-input v-model="editForm.cdCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="代码描述" prop="codeDesc" label-width="100px">
          <el-input v-model="editForm.codeDesc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序字段" prop="orderNo" label-width="100px">
          <el-input-number v-model="editForm.orderNo" :min="1" :max="10000"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('editForm')">保存</el-button>
        <el-button @click="resetForm('editForm')">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="分配权限" :visible.sync="permDialogVisible" width="600px">
      <el-form :model="permForm">
        <el-tree
          :data="permTreeData"
          show-checkbox
          ref="permTree"
          :default-expand-all="true"
          node-key="id"
          :check-strictly="true"
          :props="defaultProps"
        ></el-tree>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="permDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPermFormHandle('permForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";
export default {
  name: "Dicts",
  data() {
    return {
      searchForm: {},
      delBtlStatu: true,

      total: 20,
      size: 10,
      current: 1,

      tableData: [],
      dialogVisible: false,
      editForm: {},
      editFormRules: {
        cdType: [{ required: true, message: "请输入类别", trigger: "blur" }],
        typeDesc: [{ required: true, message: "请输入类别描述", trigger: "blur" }],
        cdCode: [{ required: true, message: "请选输入代码直", trigger: "blur" }],
        orderNo:[{ required: true, message: "请选输入排序字段", trigger: "blur" }],
        codeDesc:[{ required: true, message: "请选输入代码描述", trigger: "blur" }]
      },
      multipleSelection: [],

      permDialogVisible: false,
      permForm: {},
      defaultProps: {
        children: "children",
        label: "name"
      },
      permTreeData: []
    };
  },
  created() {
    this.getDictList();

    api.menuList().then(res => {
      this.permTreeData = res.data.data.result;
    });
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.size = val;
      this.getDictList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDictList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0;
    },
    getDictList() {
      let params = {
        cdType: this.searchForm.cdType,
        typeDesc:this.searchForm.typeDesc,
        current: this.current,
        size: this.size
      };
      api.getDictList(params).then(res => {
        this.tableData = res.data.data.result.records;
        this.total = res.data.data.result.total;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let status = this.editForm.id ? "update" : "save";
          api.saveDict(status, this.editForm).then(res => {
            if (res.data.code === 200) {
              this.$notify({
                title: "prefect",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
            }else{
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
            this.getDictList();
            this.dialogVisible = false;
            this.editForm = {};
          });
        } else {
          return false;
        }
      });
    },
    editHandler(id) {
      api.queryDictById(id).then(res => {
        this.editForm = res.data.data.result;

        this.dialogVisible = true;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    delHandle(id) {
      api.dictDelete(id).then(res => {
        this.$notify({
          title: "prefect",
          message: res.data.message,
          type: "success",
          position: "bottom-right",
        });
        this.getDictList();
      });
    },
    permHandle(id) {
      this.permDialogVisible = true;

      api.getOneRoleInfo(id).then(res => {
        this.$refs.permTree.setCheckedKeys(res.data.data.result.menuIds);

        this.permForm = res.data.data.result;
      });
    },
    submitPermFormHandle(formName) {
      let menuIds = this.$refs.permTree.getCheckedKeys();

      api.updatePerm(this.permForm.id, menuIds).then(res => {
        this.$notify({
          title: "prefect",
          message: "操作成功",
          type: "success",
          position: "bottom-right",
          onClose: () => {
            this.getRoleList();
          }
        });
        this.permDialogVisible = false;
      });
    }
  }
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
</style>
